<template>
  <MasterLayout :main_name="main_name" :link="link">
    <div class="sidebar_right_content booking_content">
      <div id="Mechanics" class="w3-container w3-border city">
        <ul class="ui-formSlide">
          <!--  book villa three section -->
          <li data-step="bk_3">
            <div class="bk_title">Your can Book from here also</div>
            <div class="bk_title_main">
              Accommodation Fit For
              <span class="font_bold"
                >{{ userProfile ? userProfile.first_name : "" }}'s</span
              >
              Profile
            </div>
            <div class="datePicker">
              <Datepicker
                type="date"
                :min="new Date().toISOString().substr(0, 10)"
                @closed="dateChange()"
                v-model="form.rangeDate"
                placeholder="Check In Check Out"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                :min-date="new Date()"
                modelAuto
                range
                multiCalendars
                multiCalendarsSolo
              />
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: checkInDateError }"
              >
                Please enter checkin checkout Date.
              </div>
            </div>
            <div class="bk_title_info_info_main_row">
              <div
                class="bk_title_info_info"
                v-for="(villa, index) in villaList"
                :key="villa.villaTypeId"
              >
                <div
                  class="bk_title_info_info_inr"
                  @click="villaClick(villa.villaTypeId)"
                >
                  <div
                    class="bk_title_info cursor_pointer"
                    :class="
                      clickVillaId.find((c) => c == villa.villaTypeId)
                        ? `activeClick`
                        : ``
                    "
                  >
                    <div class="bk_title_img">
                      <a
                        :href="villa.villa_images[0]"
                        data-lightbox="models"
                        data-title="STRUCTURE-2"
                      >
                        <img :src="villa.villa_images[0]" />
                      </a>
                      <!-- <span>View Vila</span> -->
                    </div>
                    <div class="bk_content">
                      <div class="vila_title">
                        {{ villa.villa_name }}
                      </div>
                      <div
                        class="vila_title_info"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <span> Max Quantity per</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>01 x</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                            fill="#153936"
                          />
                        </svg>
                        <span class="font_bold">{{ villa.allow_adult }}</span>
                      </div>
                      <div
                        class="vila_title_info"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <span> Price </span>&nbsp;:&nbsp;
                        <span class="font_bold">{{ villa.price }}</span>
                      </div>
                      <div
                        class="vila_title_info"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <span> Max Adult Limit</span>&nbsp;:&nbsp;
                        <span class="font_bold">{{
                          villa.max_adult_limit
                        }}</span>
                      </div>
                      <div
                        class="vila_title_info"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <span> Extra person charge</span>&nbsp;:&nbsp;
                        <span class="font_bold">{{
                          villa.extra_per_person_charge || 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="vialla_qty_main_inner">
                    <div class="vialla_qty_main">
                      <span>Villa Qty</span>
                      <div class="main_class_inner_sd select_image">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                            fill="#153936"
                          />
                        </svg>
                        <select
                          :id="`qty_${villa.villaTypeId}`"
                          name="villaQty"
                          v-model="villa_type[vTypeLower].qty"
                          @change="
                            onChangeVillaQty(
                              villa.villaTypeId,
                              villa_type[vTypeLower].qty,
                              villa_type[vTypeLower].person
                            )
                          "
                        >
                          <option
                            v-for="(vQty, vIndex) in villa.villa_quantity"
                            :key="villa.villaTypeId + '_' + vIndex"
                            :value="villa.villaTypeId + '_' + vQty"
                          >
                            {{ vQty }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="vialla_qty_main">
                      <span>Total Prax</span>
                      <div class="main_class_inner_sd select_image">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                            fill="#153936"
                          />
                        </svg>
                        <select
                          :id="`person_${villa.villaTypeId}`"
                          name="villaPerson"
                          v-model="villa_type[vTypeLower].person"
                          :disabled="villa_type[vTypeLower].qty > 0"
                          @change="
                            onChangeVillaQty(
                              villa.villaTypeId,
                              villa_type[vTypeLower].qty,
                              villa_type[vTypeLower].person
                            )
                          "
                        >
                          <option
                            v-for="(pQty, pIndex) in villa_booking[index]
                              .quantity * villa.max_adult_limit"
                            :key="villa.villaTypeId + '_' + pIndex"
                            :value="villa.villaTypeId + '_' + pQty"
                          >
                            {{ pQty }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bk_title_info_info"
                v-show="villaList.length == 0"
                style="opacity: 0.5; cursor: not-allowed"
              >
                <div class="bk_title_info_info_inr">
                  <div class="bk_title_info">
                    <div class="bk_title_img">
                      <img :src="bk_title_main" />
                      <span>View Vila</span>
                    </div>
                    <div class="bk_content">
                      <div class="vila_title">Platinum Villa</div>
                      <div class="vila_title_info">
                        <span> Max Quantity per</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>01 x</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>16</span>
                      </div>
                      <div class="main_qun">
                        <ul class="main_qun_ul">
                          <li class="main_qun_li">
                            <img :src="first_img" alt="" class="main_qun_img" />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img
                              :src="second_img"
                              alt=""
                              class="main_qun_img"
                            />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img :src="three_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="four_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="five_img" alt="" class="main_qun_img" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bk_title_info_info"
                v-show="villaList.length == 0"
                style="opacity: 0.5; cursor: not-allowed"
              >
                <div class="bk_title_info_info_inr">
                  <div class="bk_title_info">
                    <div class="bk_title_img">
                      <img :src="bk_title_main" />
                      <span>View Vila</span>
                    </div>
                    <div class="bk_content">
                      <div class="vila_title">Diamond Villa</div>
                      <div class="vila_title_info">
                        <span> Max Quantity per</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>01 x</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>16</span>
                      </div>
                      <div class="main_qun">
                        <ul class="main_qun_ul">
                          <li class="main_qun_li">
                            <img :src="first_img" alt="" class="main_qun_img" />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img
                              :src="second_img"
                              alt=""
                              class="main_qun_img"
                            />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img :src="three_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="four_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="five_img" alt="" class="main_qun_img" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bk_title_info_info"
                v-show="villaList.length == 0"
                style="opacity: 0.5; cursor: not-allowed"
              >
                <div class="bk_title_info_info_inr">
                  <div class="bk_title_info">
                    <div class="bk_title_img">
                      <img :src="bk_title_main" />
                      <span>View Vila</span>
                    </div>
                    <div class="bk_content">
                      <div class="vila_title">Cottage</div>
                      <div class="vila_title_info">
                        <span> Max Quantity per</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>01 x</span>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                            fill="#153936"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                            fill="#153936"
                          />
                        </svg>
                        <span>16</span>
                      </div>
                      <div class="main_qun">
                        <ul class="main_qun_ul">
                          <li class="main_qun_li">
                            <img :src="first_img" alt="" class="main_qun_img" />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img
                              :src="second_img"
                              alt=""
                              class="main_qun_img"
                            />
                            <span>3</span>
                          </li>
                          <li class="main_qun_li">
                            <img :src="three_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="four_img" alt="" class="main_qun_img" />
                          </li>
                          <li class="main_qun_li">
                            <img :src="five_img" alt="" class="main_qun_img" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- end book villa three section -->
        </ul>
      </div>
      <div v-show="villa_booking.length > 0" class="d_flex">
        <div
          class="bk_content villa_display"
          v-for="vb in villa_booking"
          :key="vb.villa_type_id"
        >
          <div class="vila_title">
            {{ vb.villa_name }}
          </div>
          <div class="vila_title_info">
            <span> Quantity </span>&nbsp;:&nbsp;
            <span class="font_bold">{{
              vb.quantity > 0 ? vb.quantity : 0
            }}</span>
          </div>
          <div class="vila_title_info">
            <span> Person </span>&nbsp;:&nbsp;
            <span class="font_bold">{{ vb.person > 0 ? vb.person : 0 }}</span>
          </div>
        </div>
      </div>
      <div
        class="main_reserves_row"
        :class="villaList.length == 0 ? `` : `main_reserves_row_new`"
      >
        <div class="main_reserves_col">
          <div class="main_reserves_col_inner">
            <span>Rs. {{ bookingResult ? bookingResult.total : 0 }} </span>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                fill="#153936"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                fill="#153936"
              />
            </svg>
            <span>{{ bookingResult ? bookingResult.person : 0 }}</span>
            <span class="main_class_innercol_span"
              >+Rs. {{ bookingResult ? bookingResult.gst_tax : 0 }} taxes and
              charges</span
            >
          </div>
        </div>
        <div
          class="main_reserves_col"
          v-if="bookingResult.total && bookingResult.person"
        >
          <button class="main_button_user" type="submit" @click="book">
            <span>Reserve</span>
          </button>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>

<script>
import MasterLayout from "./MasterLayout.vue";
import frame from "../../assets/images/User/Frame.png";
import bk_title_main from "../../assets/images/User/bk_title_main.png";
import first_img from "../../assets/images/User/first_img.png";
import second_img from "../../assets/images/User/second_img.png";
import three_img from "../../assets/images/User/three_img.png";
import four_img from "../../assets/images/User/four_img.png";
import five_img from "../../assets/images/User/five_img.png";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import axios from "axios";
import { ref } from "vue";
import Form from "vform";
import { isProxy, toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyBooking",
  components: { MasterLayout, Datepicker },
  data() {
    return {
      main_name: "MyBooking",
      link: [{ link: "home", name: "Home" }],
      frame: frame,
      bk_title_main: bk_title_main,
      first_img: first_img,
      second_img: second_img,
      three_img: three_img,
      four_img: four_img,
      five_img: five_img,
      token: localStorage.getItem("auth_cus_token"),
      bookingList: [],
      totalFooterShow: false,
      userProfile: null,
      checkInDateError: "none",
      villaList: [],
      form: new Form({
        id: [],
        rangeDate: ref(),
        check_in_date: "",
        check_out_date: "",
        total_adult: 0,
        total_child: 0,
        qty: 0,
        booking: {
          total: 0,
          gst_tax: 0,
          extra_person_charge: 0,
          festival_charge: 0,
          gst_tax_percent: 0,
        },
        person: 0,
        villa_booking: [],
      }),
      bookingResult: {
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
      },
      villa_booking: [],
      villa_type: {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottage: {
          qty: 0,
          person: 0,
        },
      },
      clickVillaId: [],
      vTypeLower: null,
    };
  },
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    if (!this.token) {
      this.$router.push({ name: "home" });
      window.location.href = "/";
    }
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/user-profile", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.userProfile = response.data.data;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          this.form.check_in_date = moment(date[0]).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date[1]).format("YYYY-MM-DD");
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
        }
      }
    },
    dateChange() {
      this.dateResult();
      if (this.form.rangeDate.length > 0) {
        this.checkInDateError = "none";
      }
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/search-villa",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.villaList = response.data.data;
          if (this.villaList) {
            this.villa_booking = [];
            for (var i = 0; i <= this.villaList.length; i++) {
              if (this.villaList[i] && this.villaList[i].villa_name) {
                var vType = this.villaList[i].villa_name.split(" ");
                if (vType) {
                  this.vTypeLower = vType[0].toLowerCase();
                  this.villa_type[vType[0].toLowerCase()] = {
                    qty: 0,
                    person: 0,
                  };
                }

                this.villa_booking.push({
                  villa_type_id: this.villaList[i].villaTypeId,
                  quantity: this.villa_type[vType[0].toLowerCase()].qty,
                  person: this.villa_type[vType[0].toLowerCase()].person,
                  villa_name: this.villaList[i].villa_name,
                  check_in_date: this.form.check_in_date,
                  check_out_date: this.form.check_out_date,
                });
              }
            }
          }
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    removeLocalstorage() {
      localStorage.removeItem("id");
      localStorage.removeItem("check_in_date");
      localStorage.removeItem("check_out_date");
      localStorage.removeItem("total_child");
      localStorage.removeItem("total_adult");
      localStorage.removeItem("qty");

      localStorage.removeItem("total");
      localStorage.removeItem("festival_charge");
      localStorage.removeItem("extra_person_charge");
      localStorage.removeItem("gst_tax");
      localStorage.removeItem("gst_tax_percent");
    },
    villaClick(villa_id) {
      var chek = this.clickVillaId.find((c) => c == villa_id);
      if (chek) {
        console.log(chek);
      } else {
        this.clickVillaId.push(villa_id);
      }
    },
    onChangeVillaQty(villa_id, qty, person) {
      for(let  [inQ, villaQ] of (this.villa_booking).entries()) {
          if(this.villa_booking[inQ].villa_type_id == villa_id) {
              if(this.form.id.includes(villaQ.villa_type_id)) {console.log('villaQ: ', villaQ.villa_type_id);} else {
                  this.form.id.push(villaQ.villa_type_id);
              }
              this.villa_booking[inQ] = {
                  villa_type_id: villaQ.villa_type_id,
                  quantity: parseInt(qty.split('_') ? qty.split('_')[1] : 0),
                  person: person ? parseInt(person.split('_') ? person.split('_')[1] : 0) : 0,
                  villa_name: villaQ.villa_name,
                  check_in_date: this.form.check_in_date,
                  check_out_date: this.form.check_out_date,
              };
              this.villaBookingTotal();
          }
      }
    },
    async villaBookingTotal() {
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: this.villa_booking,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.bookingResult = response.data.data;
          this.villa_price = this.bookingResult.total;
          if (this.bookingResult) {
            this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax
              ? this.bookingResult.gst_tax
              : 0;
            this.bookingResult.gst_tax =
              (this.bookingResult.total * this.bookingResult.gst_tax) / 100;
            this.bookingResult.total =
              parseInt(this.bookingResult.total) +
              parseInt(this.bookingResult.extra_person_charge) +
              parseInt(this.bookingResult.festival_charge) +
              parseInt(this.bookingResult.gst_tax);
          }
          console.log("response: ", this.bookingResult);
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    ...mapActions(["addPlatinum"]),
    book() {
      this.form.booking.total = this.bookingResult.total;
      this.form.booking.gst_tax = this.bookingResult.gst_tax;
      this.form.booking.extra_person_charge =
        this.bookingResult.extra_person_charge;
      this.form.booking.festival_charge = this.bookingResult.festival_charge;
      this.form.booking.gst_tax_percent = this.bookingResult.gst_tax_percent;
      this.form.total_adult = this.bookingResult.person;
      this.form.person = this.bookingResult.person;
      this.form.villa_booking = this.villa_booking;
      this.addPlatinum(this.form);
      let rawData = this.allPlatinums;
      console.log("rawData: ", rawData);
      if (isProxy(this.allPlatinums)) {
        rawData = toRaw(this.allPlatinums);
      }
      if (rawData && rawData[0].id) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
  computed: mapGetters(["allPlatinums"]),
};
</script>

<style scoped>
.tab_inner_content_main {
  height: fit-content;
  margin: 10px;
  padding: 15px;
  display: inline-flex;
  width: 48%;
}

.tab_inner_content_image {
  width: 30%;
}

.tab_inner_content_image img {
  width: 100%;
  border-radius: 10px;
}

.d_flex {
  display: flex;
}

.tab_inner_content_date {
  padding: 0 10px;
  font-size: 15px;
  width: 100%;
  text-align: left;
}

.tab_inner_content_add_user {
  display: flex;
  align-items: center;
  border-radius: 100px;
  margin-top: 0;
  padding: 0 10px 0 0px;
  background: transparent;
  font-size: 13px;
}
.aview_bar {
  padding: 8px;
}

.lightbox {
  top: 50% !important;
}
.font_bold {
  font-weight: 600;
}

.chkbox {
  display: flex;
  align-items: flex-start;
  height: 20px;
  margin-right: 10px;
}

.activeClick {
  background-color: #42aea3;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cursor_pointer {
  cursor: pointer;
}
.main_reserves_row_new {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
}
.d_flex {
  display: flex;
}
.villa_display {
  width: 33%;
  margin-left: 20px;
}

#dm .vila_title_info.activeClick {
  color: #fff;
}
</style>
